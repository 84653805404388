import React from 'react'

import './pricing.css'
import FAQ14 from '../../components/faq/faq14'
import Footer from '../../components/footer/footer'
import Pricing3 from '../../components/prices/price3/pricing3'
import Testimonial6 from '../../components/testimonials/testimonial6'
import PricingHeader from '../../components/pricingHeader/pricingHeader'
import Pricing6 from '../../components/prices/price6/pricing6'
import Navbar from '../../components/Navbar/Navbar'

const Pricing = (props) => {
  return (
    <div className="pricing-container">
      {/* <PricingHeader /> */}
      <Navbar homePage={false}/>
      <Pricing3 />
      {/* <Pricing6/> */}
      <Testimonial6 />
      <FAQ14 />
      <Footer />
    </div>
  )
}

export default Pricing
