import React from 'react'
import PropTypes from 'prop-types'
import './whyChooseUsComponent.css'

const WhyChooseUs = (props) => {
    return (
        <div className="whyChooseUs-whyChooseUs">
            <div className="whyChooseUs-heading">
                <img alt="WhyChooseUs-section-image" src={props.img} className="whyChooseUs-image " />
                <h3 className="whyChooseUs-header">{props.title}</h3>
            </div>
        </div>
    )
}
WhyChooseUs.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string
}

export default WhyChooseUs
