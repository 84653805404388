import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import "./style.css";
import Pricing from "./views/pricing/pricing";
import Home from "./views/home/home";
import NotFound from "./views/notFound/not-found";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route path="*" element={<Navigate to="*" />} />
      </Routes>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
