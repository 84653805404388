import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './faq14.css'

const FAQ14 = (props) => {
  const [faq4Visible, setFaq4Visible] = useState(false)
  const [faq5Visible, setFaq5Visible] = useState(false)
  const [faq3Visible, setFaq3Visible] = useState(false)
  const [faq2Visible, setFaq2Visible] = useState(false)
  const [faq1Visible, setFaq1Visible] = useState(false)
  return (
    <div className="faq14-faq8 thq-section-padding">
      <div className="faq14-max-width thq-section-max-width">
        <div className="faq14-container thq-flex-column">
          <div className="faq14-section-title thq-flex-column">
            <h2>
              {props.heading1 ?? (
                <Fragment>
                  <h2 className="faq14-text11 thq-heading-2">FAQs</h2>
                </Fragment>
              )}
            </h2>
            {/* <p>
              {props.content1 ?? (
                <Fragment>
                  <p className="faq14-text16 thq-body-large">
                    Everything You Need to Know About Booking Tests Online with Praavi.
                  </p>
                </Fragment>
              )}
            </p> */}
            {/* <button type="button" className="thq-button-filled">
              <span>
                {props.action1 ?? (
                  <Fragment>
                    <span className="faq14-text15">Contact</span>
                  </Fragment>
                )}
              </span>
            </button> */}
          </div>
          <div className="faq14-list thq-flex-column">
            <div className="thq-divider-horizontal"></div>
            <div className="faq14-faq1">
              <div
                onClick={() => setFaq1Visible(!faq1Visible)}
                className="faq14-trigger"
              >
                <p>
                  {props.faq1Question ?? (
                    <Fragment>
                      <p className="faq14-text18 thq-body-large">
                        How Do I Book a Diagnostic Test Online with Praavi?
                      </p>
                    </Fragment>
                  )}
                </p>
                <div className="faq14-icons-container">
                  {!faq1Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq14-icon">
                        <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                      </svg>
                    </div>
                  )}
                  {faq1Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq14-icon02">
                        <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq1Visible && (
                <div className="faq14-container03">
                  <span className="thq-body-small">
                    Booking a diagnostic test with Praavi is a straightforward and hassle-free process.
                    Simply visit our website, choose the type of test you need
                    , choose your preferred location
                    and select a trusted local lab from our network.
                    You can compare prices and view lab deatils.
                    Once you’ve selected your options, confirm your booking by providing some basic information and
                    choose a date slot that fits your schedule. You’ll receive a confirmation message with all the details,
                    and you’re all set! This seamless process saves you time and gives you control over your health testing.
                  </span>
                </div>
              )}
            </div>
            <div className="thq-divider-horizontal"></div>
            <div className="faq14-faq2">
              <div
                onClick={() => setFaq2Visible(!faq2Visible)}
                className="faq14-trigger1"
              >
                <p>
                  {props.faq2Question ?? (
                    <Fragment>
                      <p className="faq14-text19 thq-body-large">
                        What Types of Health Tests Can I Book Through Praavi?
                      </p>
                    </Fragment>
                  )}
                </p>
                <div className="faq14-icons-container1">
                  {!faq2Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq14-icon04">
                        <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                      </svg>
                    </div>
                  )}
                  {faq2Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq14-icon06">
                        <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq2Visible && (
                <div className="faq14-container06">
                  <span className="thq-body-small">
                    Praavi offers a comprehensive range of diagnostic tests to cater to all your health needs.
                    You can book tests like Complete Blood Count (CBC), Liver Function Tests (LFT), Kidney Function Tests (KFT),
                    Thyroid Function Tests, diabetes screenings (such as Fasting Blood Sugar and HbA1c),
                    Lipid Profile tests for heart health, and full-body health checkups.
                    We also provide specialized packages for specific health concerns such as women's health, senior citizens,
                    and more. By partnering with top-rated local labs, we ensure that you receive accurate, reliable,
                    and timely results that help you make informed decisions about your health.
                  </span>
                </div>
              )}
            </div>
            <div className="thq-divider-horizontal"></div>
            <div className="faq14-faq3">
              <div
                onClick={() => setFaq3Visible(!faq3Visible)}
                className="faq14-trigger2"
              >
                <p>
                  {props.faq3Question ?? (
                    <Fragment>
                      <p className="faq14-text13 thq-body-large">
                        How Soon Can I Get My Test Results?
                      </p>
                    </Fragment>
                  )}
                </p>
                <div className="faq14-icons-container2">
                  {!faq3Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq14-icon08">
                        <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                      </svg>
                    </div>
                  )}
                  {faq3Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq14-icon10">
                        <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq3Visible && (
                <div className="faq14-container09">
                  <span className="thq-body-small">
                    Test result turnaround times depend on the type of test and the lab you select,
                    but most results are available within 24 to 48 hours. Once your results are ready,
                    you will receive an email or SMS notification. You can securely access your test reports
                    online through your email and whatsApp. Our platform ensures that your health
                    information is private and safe, allowing you to easily share your results with your doctor
                    or healthcare provider. Quick access to test results helps you take timely action for better
                    health management.
                  </span>
                </div>
              )}
            </div>
            <div className="thq-divider-horizontal"></div>
            <div className="faq14-faq4">
              <div
                onClick={() => setFaq4Visible(!faq4Visible)}
                className="faq14-trigger3"
              >
                <p>
                  {props.faq4Question ?? (
                    <Fragment>
                      <p className="faq14-text17 thq-body-large">
                        Why Should I Choose Praavi for Booking My Diagnostic Tests?
                      </p>
                    </Fragment>
                  )}
                </p>
                <div className="faq14-icons-container3">
                  {!faq4Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq14-icon12">
                        <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                      </svg>
                    </div>
                  )}
                  {faq4Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq14-icon14">
                        <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq4Visible && (
                <div className="faq14-container12">
                  <span className="thq-body-small">
                    Praavi is designed to make your diagnostic testing experience simple, convenient, and reliable.
                    By choosing Praavi, you benefit from a user-friendly platform that lets you book tests from trusted
                    local labs with just a few clicks. We offer competitive pricing with no hidden costs,
                    fast and secure access to test results, and dedicated customer support to assist you throughout the process.
                    Whether you need routine health checks or specialized tests, Praavi provides the transparency, quality,
                    and convenience you need to manage your health confidently.
                  </span>
                </div>
              )}
            </div>
            <div className="thq-divider-horizontal"></div>
            <div className="faq14-faq5">
              <div
                onClick={() => setFaq5Visible(!faq5Visible)}
                className="faq14-trigger4"
              >
                <p>
                  {props.faq5Question ?? (
                    <Fragment>
                      <p className="faq14-text23 thq-body-large">
                        How Do I Know My Diagnostic Test Booking is Confirmed?
                      </p>
                    </Fragment>
                  )}
                </p>
                <div className="faq14-icons-container4">
                  {!faq5Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq14-icon16">
                        <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                      </svg>
                    </div>
                  )}
                  {faq5Visible && (
                    <div>
                      <svg viewBox="0 0 1024 1024" className="faq14-icon18">
                        <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq5Visible && (
                <div className="faq14-container15">
                  <span className="thq-body-small">
                    Once you complete the booking process on Praavi, you will receive an confirmation via email
                    and SMS with all the details of your appointment, including the test you've booked, the chosen lab,
                    date, and time. If you have any concerns or need further assistance, our customer support team is
                    always available to help ensure your booking is smooth and confirmed.
                  </span>
                </div>
              )}
            </div>
            <div className="thq-divider-horizontal"></div>
          </div>
        </div>
        <div className="faq14-content thq-flex-column">
          <div className="faq14-content1">
            <h2>
              {props.heading2 ?? (
                <Fragment>
                  <h2 className="faq14-text12 thq-heading-2">
                    Still have a question?
                  </h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.content2 ?? (
                <Fragment>
                  <span className="faq14-text20 thq-body-large">
                    <span>
                      Need help? Reach out to us, and we’ll be happy to assist!
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <button type="button" className="thq-button-filled">
            <span>
              {props.action2 ?? (
                <Fragment>
                  <a
                    href="mailto:contact@praavihealth.com?subject=Main"
                    className="pricing-link14"
                  >
                    <span className="faq14-text14">Email us</span>
                  </a>

                </Fragment>
              )}
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

FAQ14.defaultProps = {
  heading1: undefined,
  heading2: undefined,
  faq3Question: undefined,
  action2: undefined,
  action1: undefined,
  content1: undefined,
  faq4Question: undefined,
  faq1Question: undefined,
  faq2Question: undefined,
  content2: undefined,
  faq5Question: undefined,
}

FAQ14.propTypes = {
  heading1: PropTypes.element,
  heading2: PropTypes.element,
  faq3Question: PropTypes.element,
  action2: PropTypes.element,
  action1: PropTypes.element,
  content1: PropTypes.element,
  faq4Question: PropTypes.element,
  faq1Question: PropTypes.element,
  faq2Question: PropTypes.element,
  content2: PropTypes.element,
  faq5Question: PropTypes.element,
}

export default FAQ14
