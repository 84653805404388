import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePicker = ({handleSelect}) => {
  const [startDate, setStartDate] = useState(null);
  const today = new Date();

  const handleChange = (date) => {
    const newDate=date.toLocaleDateString('en-GB')
    const dateToSend = { target: { name: "date", value: newDate } };
    handleSelect(dateToSend);
        setStartDate(date);
  };

  return (
    <div
      className="custom-date-picker"
      style={{
        display: "flex",
        width: "100%",
      }}
    >
      <div style={{ flexGrow: 1 }}>
        <DatePicker
          selected={startDate}
          onChange={handleChange}
          placeholderText="Date"
          dateFormat="dd-MM-yyyy"
          className="custom-date input book-input"
          minDate={today}
          name="date"
          style={{
            width: "100%",
            border: "none",
            outline: "none",
          }}
        />
      </div>

      <FontAwesomeIcon
        icon={faCalendar}
        onClick={() => document.querySelector(".custom-date").focus()}
        style={{
          marginLeft: "8px",
          cursor: "pointer",
          color: "#888",
          flexShrink: 0,
        }}
      />
    </div>
  );
};

export default CustomDatePicker;
