import React, { useEffect, useState } from "react";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
import Error from "../Error/Error";
import BookingSuccess from "../BookingSuccess/BookingSuccess";
import { bookingService } from "../../services/bookingService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialFormData = {
  name: "",
  email: "",
  phone: "",
  date: "",
  address: "",
  pincode: "",
  landmark: "",
};

const Modal = ({ isOpen, onClose, details }) => {
  const [formData, setFormData] = useState(initialFormData);

  const [error, setError] = useState({});
  const [isBookingConfirmed, setIsBookingConfirmed] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const submitForm = async () => {
    let newError = {};
    let hasError = false;
    for (let key in formData) {
      if (formData[key] === null || formData[key] === "") {
        newError[key] = `* Please enter ${key}`;
        hasError = true;
      }
    }
    setError(newError);
    if (!hasError) {
      const data = {
        ...formData,
        diagnosticCenterId: details.diagnosticCenterId,
      };
      const bookingResponse = await bookingService.addBooking(data);
      if (bookingResponse.status === 201) {
        setIsBookingConfirmed(true);
      } else {
        toast.error("Some error occurred");
      }
    }
  };

  const handleModalClose = () => {
    setFormData(initialFormData)
    setError({})
    setIsBookingConfirmed(false)
    onClose()
  }

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <>
      <div onClick={onClose} className="modal-transparent">
        <div onClick={(e) => e.stopPropagation()} className="modal-white">
          <ToastContainer />
          <button
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "black",
            }}
            onClick={handleModalClose}
          >
            <svg viewBox="0 0 1024 1024" className="home-icon3">
              <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
            </svg>
          </button>
          {isBookingConfirmed ? (
            <BookingSuccess />
          ) : (
            <section id="book" className="home-book5">
              <div className="home-heading17">
                <p className="home-text69">Book your test</p>
              </div>
              <div className="home-form">
                <div className="home-inputs">
                  <div className="home-input-div">
                    <input
                      type="text"
                      placeholder="Name"
                      autoComplete="name"
                      className="input book-input"
                      onChange={handleChange}
                      name="name"
                    />
                    <Error text={error.name} />
                  </div>

                  <div className="home-input-div">
                    <input
                      type="email"
                      placeholder="Email"
                      autoComplete="email"
                      className="input book-input"
                      name="email"
                      onChange={handleChange}
                    />
                    <Error text={error.email} />
                  </div>
                  <div className="home-input-div">
                    <input
                      type="tel"
                      placeholder="Phone"
                      autoComplete="tel"
                      className="input book-input"
                      name="phone"
                      onChange={handleChange}
                    />
                    <Error text={error.phone} />
                  </div>

                  <div className="home-input-div">
                    <CustomDatePicker handleSelect={handleChange} />
                    <Error text={error.date} />
                  </div>
                  <div className="home-input-div">
                    <input
                      type="text"
                      placeholder="Address"
                      className="input book-input"
                      name="address"
                      onChange={handleChange}
                    />
                    <Error text={error.address} />
                  </div>
                  <div className="home-input-div">
                    <input
                      type="number"
                      placeholder="Pincode"
                      className="input book-input"
                      name="pincode"
                      onChange={handleChange}
                    />
                    <Error text={error.pincode} />
                  </div>
                  <div className="home-input-div">
                    <input
                      type="text"
                      placeholder="Landmark"
                      className="input book-input"
                      name="landmark"
                      onChange={handleChange}
                    />
                    <Error text={error.landmark} />
                  </div>
                  <div className="home-lower">
                    <div className="home-button">
                      <button
                        className="home-book6 button button-main"
                        onClick={submitForm}
                      >
                        <span>Book</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </div>
    </>
  );
};

export default Modal;

// for virtual appointment and in person appointment-

/* <div className="home-types1">
                <div className="book-type">
                  <span className="home-text71">In person appointment</span>
                </div>
                <div className="book-type">
                  <span className="home-text72">Virtual appointment</span>
                </div>
              </div> */
