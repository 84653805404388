import axios from "axios";

export const diagnosticCenterService = {
    findDiagnosticCenter: async (locationId, testId) => {
        try {
            const response = await axios.post(
              `${process.env.REACT_APP_BACKEND_URL}/diagnosticCenter/findDiagnosticCenter`,
              { locationId, testId }
            );
            return response;
        }
        catch (error) {
            return error
        }
    },
};
