import React from 'react'
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Navbar = ({ homePage }) => {

  return (
    <header className="home-header07" style={{ zIndex: 500 }}>
      <header data-thq="thq-navbar" className="home-navbar">
        <div className="home-left">
          <h1
            className="home-header03"
            style={{ fontWeight: "bold", fontSize: "32px" }}
          >
            <Link to="/">Praavi</Link>
          </h1>
          <nav className="home-links">
            <Link to="/#home-section" className="home-link02">
              Home
            </Link>


            <HashLink
              className="home-link01"
              smooth
              to={homePage ? "#our-services" : "/#our-services"}
            >
             Our Diagnostic Services
            </HashLink>

            <HashLink
              className="home-link01"
              smooth
              to={homePage ? "#why-choose-us" : "/#why-choose-us"}
            >
              Why choose us
            </HashLink>
            {/* <HashLink
              smooth
              to={homePage ? "#diagnostic-centres" : "/#diagnostic-centres"}
              className="home-link03"
            >
              Partner diagnostic centres
            </HashLink> */}
          </nav>
        </div>
        <div data-thq="thq-navbar-btn-group" className="home-right">
          <button href="tel:9198593378" className="home-phone button">
            <img
              alt="image"
              src="/Icons/phone.svg"
              className="home-image06"
              style={{ height: "19px" }}
            />
            <a href="tel:9198593378" className="home-text06">+91-9198593378</a>
          </button>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              marginRight: "10px",
            }}
          >
            <img
              alt="image"
              src="/Icons/location.svg"
              className="home-image07"
              style={{ height: "28px" }}
            />
            <span style={{ fontWeight: "600" }}>Lucknow</span>
          </div>
        </div>

        <div className="home-burger-div">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              marginRight: "10px",
            }}
          >
            <img
              alt="image"
              src="/Icons/location.svg"
              className="home-image07"
              style={{ height: "28px" }}
            />
            <span style={{ fontWeight: "600" }}>Lucknow</span>
          </div>
          <div data-thq="thq-burger-menu" className="home-burger-menu">
            <svg viewBox="0 0 1024 1024" className="home-icon1">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
        </div>

        <div data-thq="thq-mobile-menu" className="home-mobile-menu">
          <div
            data-thq="thq-mobile-menu-nav"
            data-role="Nav"
            className="home-nav"
          >
            <div className="home-container1">
              <h1
                className="home-header03"
                style={{ fontWeight: "bold", fontSize: "32px" }}
              >
                <Link to="/">Praavi</Link>
              </h1>
              <div data-thq="thq-close-menu" className="home-menu-close">
                <svg viewBox="0 0 1024 1024" className="home-icon3">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>

            <nav
              data-thq="thq-mobile-menu-nav-links"
              data-role="Nav"
              className="home-nav1"
            >
              <Link to="/" className="home-link-mb">
                Home
              </Link>
              <HashLink
                smooth
                className="home-link-mb"
                to={homePage ? "#our-services" : "/#our-services"}
              >
                Diagnostic services
              </HashLink>
              <HashLink
                smooth
                className="home-link-mb"
                to={homePage ? "#why-choose-us" : "/#why-choose-us"}
              >
                Why choose us
              </HashLink>
              <HashLink
                smooth
                className="home-link-mb"
                to={homePage ? "#diagnostic-centres" : "/#diagnostic-centres"}
              >
                Partner diagnostic centres
              </HashLink>
            </nav>
          </div>
        </div>
      </header>
    </header>
  );
}

export default Navbar
