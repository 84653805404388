import React from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Error from "../Error/Error";

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "85%",
    display: "flex",
    alignItems: "center",
    padding: "4px",
    borderRadius: "56px",
    backgroundColor: "#f8f9fa",
    flex: 1,
    color:"white"
  }),
  control: (provided) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    minHeight: "20px",
    width: "100%",
    fontSize: "16px",
    backgroundColor: "#f8f9fa",
    marginTop: "0px",
    borderRadius:'56px',
    cursor:'pointer'
  }),
  menu: (provided) => ({
    ...provided,
    width: "100%",
    marginTop: "8px",
    borderRadius: "8px",
    border: "1px solid #ced4da",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    // marginLeft: "-20%",
    textAlign: "center",
    color:'white',
    zIndex:102

  }),
  option: (provided, state) => ({
    ...provided,

    padding: "10px",
    fontSize: "16px",
    color: state.isSelected ? "white" : "#495057",
    backgroundColor: state.isSelected ? "rgb(36, 97, 255)" : "white",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(36, 97, 255)",
      color: "white",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "black",
  }),
};

const CustomDropdown = ({
  items,
  selectedItem,
  handleChange,
  icon,
  placeholder,
  errorText
}) => {
  const options = items.map((item) => ({
    value: item._id,
    label: item.locationName || item.testName
  }));

  return (
    <div className="custom-dropdown" style={{display:'flex',flexDirection:'column', flex: 2, width: "100%", cursor: "pointer" }}>
      <div
        className="custom-dropdown"
        style={{
          flex: 2,
          width: "100%",
          cursor: "pointer",
          border: "5px solid rgb(36, 97, 255)",
          borderRadius: "56px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#f8f9fa",
            borderRadius: "56px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            width: "100%",
            margin: "auto",
          }}
        >
          {icon && (
            <FontAwesomeIcon
              style={{
                color: "#1c4a5a",
                marginRight: "8px",
                fontSize: "20px",
              }}
              icon={icon || ""}
            />
          )}
          <Select
            value={options.find((option) => option.value === selectedItem)}
            onChange={(selectedOption) => handleChange(selectedOption.value)}
            options={options}
            styles={customStyles}
            placeholder={placeholder}
          />
        </div>
      </div>
      <Error text={errorText} />
    </div>
  );
};

export default CustomDropdown;
