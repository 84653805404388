import React from 'react'

const BookingSuccess = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          height: "400px",
          width: "350px",
          borderRadius: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
          gap: "20px",
        }}
      >
        <img src="/Icons/success.svg" style={{ width: "150px" }} />
        <p style={{ textAlign: "center", fontSize: "16px" }}>
          Your Booking is confirmed !!
        </p>
        <p style={{ textAlign: "center", fontSize: "16px" }}>
          Thank you for choosing
          <p style={{ fontWeight: "bolder",marginTop:'5px',fontSize:'22px' }}>Praavi</p>
        </p>
        <p style={{ textAlign: "center", fontSize: "16px" }}>
          You will receive a confirmation email and SMS with all the details
          shortly
        </p>
      </div>
    </div>
  );
}

export default BookingSuccess
