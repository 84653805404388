import axios from "axios"

export const locationService = {
    
    allLocation: async () => {
        console.log(process.env.REACT_APP_BACKEND_URL)
        try {
            const response = await axios.get(
              `${process.env.REACT_APP_BACKEND_URL}/location/getAllLocation`
            );
            return response
        }
        catch (error) {
            return error
        }
    }
}