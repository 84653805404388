import axios from "axios";

export const bookingService = {
    addBooking: async (bookingData) => {
        try {
            const response = await axios.post(
              `${
                process.env.REACT_APP_BACKEND_URL
                //  || "http://localhost:8000"
              }/booking/addBooking`,
              {
                ...bookingData,
                createdDate: new Date(),
              }
            );
            return response;
        }
        catch (error) {
            return error;
        }
    },
};
