import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './content-list6.css'

const AllOurService = (props) => {
  return (
    <div id="AllOurService"
      className={`content-list6-container thq-section-padding ${props.rootClassName} `}
    >
      <div className="content-list6-max-width thq-section-max-width">
        <div className="content-list6-container1 thq-flex-column">
          <span className="content-list6-text08 thq-heading-2">
            <Fragment>
              <h5 className="content-list6-text08 thq-heading-4">
                All our services
              </h5>
            </Fragment>
          </span>
        </div>
        <div className="content-list6-container2 thq-flex-column">
          <p>
            <Fragment>
              <p className="content-list6-text26 thq-body-small">
                Full Body Checkups | Covid 19 Test | Heart Test | Kidney Test | Liver Test | CBC Test | Cholesterol Test |
                HbA1c Test | Hepatitis B Test | Kidney Function Test | Liver Function Test | Sugar Test | Thyroid Test |
                Typhoid Test | Uric Acid Test | Vitamin B12 Test | Vitamin D Test | Allergy Test | Arthritis Test |
                Cancer Test | Bone And Joint | Dengue Test | Diabetes Test | Rheumatoid Test | Tuberculosis Test |
                Infertility Test | Diabetes Care | Anemia Test | GastroIntestinal | Autoimmune Disorders | Fever Test
              </p>
            </Fragment>
          </p>
        </div>
      </div>
    </div>
  )
}

export default AllOurService
