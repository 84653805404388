import React from "react";
import WhyChooseUs from "../howItWorks/whyChooseUsComponent.js";

const HowItWorks = () => {
    return (
        <section id="why-choose-us" className="home-why">
            <div className="home-heading09">
                <h2 className="home-header09">Why choose us</h2>
                <p className="home-header10">
                    Prioritizing Your Health with Safe, Transparent Diagnostics
                </p>
            </div>
            <div className="home-content03">
                <div className="home-grid2">
                    <div className="home-howItWorks-wrapper">
                        <WhyChooseUs title={"Transparency and Quality"} img={"/Icons/verify.svg"}
                        > </WhyChooseUs>
                    </div>
                    <div className="home-howItWorks-wrapper">
                        <WhyChooseUs title={"Local Expertise and Trusted Diagnostic Services"} img={"/Icons/handshake-regular.svg"}
                        ></WhyChooseUs>
                    </div>
                    <div className="home-howItWorks-wrapper">
                        <WhyChooseUs title={"Cutting-Edge Technology for Accurate Results"} img={"/Icons/chart-line-solid.svg"}
                        ></WhyChooseUs>
                    </div>
                    <div className="home-howItWorks-wrapper">
                        <WhyChooseUs title={"Accredited Diagnostic Centers"} img={"/Icons/verified_lab.svg"}
                        ></WhyChooseUs>
                    </div>
                    <div className="home-howItWorks-wrapper">
                        <WhyChooseUs title={"Hassle-Free Online Test Booking"} img={"/Icons/event_available.svg"}
                        ></WhyChooseUs>
                    </div>
                    <div className="home-howItWorks-wrapper">
                        <WhyChooseUs title={"Secure and Confidential Data Handling"} img={"/Icons/encrypted.svg"}
                        ></WhyChooseUs>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default HowItWorks