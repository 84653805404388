import React from 'react'
import './home.css'
import Footer from '../../components/footer/footer'
import Details from '../../components/details/details'
import HomeHero from '../../components/homeHero/homeHero'
import HomeDoctor from '../../components/doctors/homeDoctor'
import HowItWorks from '../../components/howItWorks/howItWorksVideo'
import OurPractices from '../../components/ourPractices/ourPractice'
import AllOurService from '../../components/content-list6'

const Home = (props) => {
  return (
    <div className="home-container">
      <HomeHero />
      <OurPractices />
      <HowItWorks />
      <Details />
      {/* <HomeDoctor /> */}
      <AllOurService />
      <Footer />
    </div>
  )
}

export default Home


