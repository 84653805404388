import React from 'react'

const Error = ({text,customStyle}) => {
  return (
    <div className='custom-error' style={{minHeight:'12px',fontSize:'12px',paddingLeft:'10px',...customStyle}}>
      {text}
    </div>
  )
}

export default Error
