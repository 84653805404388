import React from "react";
import Practice from "./practice";

const OurPractices = () => {
    return (
        <section className="home-practices1" id="our-services">
            <div className="home-heading08">
                <h2 className="home-text14">Our Diagnostic Services</h2>
                <p className="home-text15">
                    Your Health Matters – Trusted and Transparent Diagnostic Testing Near You
                </p>
            </div>
            <div className="home-content02">
                <div className="home-grid1">
                    <div className="home-practice-wrapper">
                        <Practice title={"Full Body Health Checkups"}
                            description={"Get a complete health checkup to understand how well your body is doing. We check your blood, heart, liver, and kidneys to help you stay healthy and catch any problems early."}></Practice>
                    </div>
                    <div className="home-practice-wrapper">
                        <Practice title={"Diabetes Health Tests"}
                            description={"Blood sugar tests like Fasting, Random, and HbA1c monitor glucose levels to diagnose and manage diabetes effectively."}></Practice>
                    </div>
                    <div className="home-practice-wrapper">
                        <Practice title={"Heart Health Monitoring"}
                            description={"Lipid profile test measures cholesterol levels, helping assess heart health and prevent cardiovascular diseases."}></Practice>
                    </div>
                    <div className="home-practice-wrapper">
                        <Practice title={"Vital Organ Health Tests"}
                            description={"Keep your vital organs, like your liver and kidneys, in top shape. Our tests check how well they’re working so you can stay healthy and worry-free."}></Practice>
                    </div>
                </div>
                {<a href="#AllOurService" data-open="practices" className="button button-main our-services-button">
                    <span>All our services</span>
                </a>}
            </div>
        </section>
    )
}
export default OurPractices