import axios from "axios"

export const testService = {
    allTest: async () => {
        try {
            console.log(process.env.REACT_APP_IP_ADDRESS)
            const response = await axios.get(
              `${process.env.REACT_APP_BACKEND_URL}/test/getAllTest`
            );
            return response
        }
        catch (error) {
            return error
        }
    }
}