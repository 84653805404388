import React,{useState} from "react";
import { HashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Link } from "react-router-dom";


const Footer = () => {

  const [homePage, setHomepage] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (path === "/" || path === "/pricing") {
      setHomepage(false); 
    } else {
      setHomepage(true);
    }
  }, [location]); 

    return (
      <div className="pricing-footer">
        <div className="pricing-left1">
          <div className="pricing-brand">
            <h1 className="pricing-text007" style={{ color: "white" }}>
              Praavi
            </h1>
            <p className="pricing-text131">
              Where Local Labs Meet Smart Diagnostics.
            </p>
          </div>
          <div className="pricing-socials">
            <div className="social">
              <img
                alt="image"
                src="/Icons/insider.svg"
                className="pricing-image5"
              />
            </div>
            <div className="social">
              <img
                alt="image"
                src="/Icons/instagram.svg"
                className="pricing-image6"
              />
            </div>
            <div className="social">
              <img
                alt="image"
                src="/Icons/twitter.svg"
                className="pricing-image7"
              />
            </div>
          </div>
          <div className="pricing-legal">
            <span className="pricing-copyright">
              © 2024 Praavi. All Rights Reserved.
            </span>
            {/* <span className="legal-link">Privacy Policy</span>
                    <span className="legal-link">Terms of Use</span> */}
          </div>
        </div>
        <div className="pricing-right1">
          <div className="pricing-list">
            {/* <span className="pricing-header1">Menu</span> */}
            <div className="pricing-links1">
              {/* <a href="#home-section" className="pricing-link04">
                Home
              </a> */}
            
              <HashLink
                smooth
                className="pricing-link04"
                to={homePage ? "#home-section" : "/#home-section"}
              >
                Home
              </HashLink>
              <HashLink
                smooth
                className="pricing-link04"
                to={homePage ? "#our-services" : "/#our-services"}
              >
                Our Diagnostic services
              </HashLink>
              <HashLink
                smooth
                className="pricing-link04"
                to={homePage ? "#why-choose-us" : "/#why-choose-us"}
              >
                Why choose us
              </HashLink>
              {/* <HashLink
                smooth
                className="pricing-link04"
                to={homePage ? "#why-choose-us" : "/#why-choose-us"}
              >
                Why choose us
              </HashLink> */}

              {/* <a href="#our-practices" className="pricing-link04">
                Our diagnostic services
              </a> */}
              {/* <a href="#why-choose-us" className="pricing-link04">
                Why choose us
              </a> */}
              {/* <a href="#diagnostic-centres" className="pricing-link04">
                Partner diagnostic centres
              </a> */}
            </div>
          </div>
          {/* <div className="pricing-list1">
                    <span className="pricing-header2">Resources</span>
                    <div className="pricing-links2">
                        <span className="pricing-link09">Test Results</span>
                        <span className="pricing-link10">Patients</span>
                        <span className="pricing-link11">Doctors</span>
                        <span className="pricing-link12">Health</span>
                    </div>
                </div> */}
          <div className="pricing-list2">
            <span className="pricing-header3">Contact</span>
            <div className="pricing-links3">
              <span className="pricing-link13">
                Ram Kuti, Raj Estate, Lucknow
              </span>
              <a
                href="mailto:praavi.health@gmail.com?subject=Main"
                className="pricing-link14"
              >
                contact@praavihealth.com
              </a>
              <a href="tel:9198593378" className="pricing-link15">
                9198593378, 8090225906
              </a>
            </div>
          </div>
        </div>
        <div className="pricing-legal1">
          {/* <div className="pricing-row">
                    <span className="legal-link">Privacy Policy</span>
                    <span className="legal-link">Terms of Use</span>
                </div> */}
          <span className="pricing-copyright5">
            © 2024 Praavi. All Rights Reserved.
          </span>
        </div>
      </div>
    );
}

export default Footer