import React, { Fragment } from "react";

import PropTypes from "prop-types";

import "./testimonial6.css";

const Testimonial6 = (props) => {
  return (
    <div className="thq-section-padding testimonial-section">
      <div className="testimonial6-max-width thq-section-max-width">
        <div
          data-thq="slider"
          data-loop="true"
          data-autoplay="true"
          data-navigation="true"
          data-pagination="true"
          className="testimonial6-slider swiper"
        >
          <div data-thq="slider-wrapper" className="swiper-wrapper">
            <div
              data-thq="slider-slide"
              className="testimonial6-slider-slide swiper-slide"
            >
              <div className="testimonial6-card">
                {/* <img
                  alt={props.company1LogoAlt}
                  src={props.company1LogoSrc}
                  className="testimonial6-logo"
                /> */}
                <h2>Praavi</h2>
                <p>
                  {props.review1 ?? (
                    <Fragment>
                      <span className="testimonial6-text40 thq-body-large">
                        I booked my diagnostic tests through Praavi, and the
                        process was incredibly easy and quick. The platform
                        showed me all the local labs and their prices. I got my
                        results in less than 24 hours, and the support team was
                        super helpful. Highly recommend Praavi to anyone looking
                        for reliable and hassle-free health testing!"
                      </span>
                    </Fragment>
                  )}
                </p>
                <div className="testimonial6-avatar">
                  <img
                    alt={props.author1Alt}
                    src={props.author1Src}
                    className="testimonial6-avatar-image thq-img-ratio-1-1 thq-img-round"
                  />
                  <div className="testimonial6-avatar-content">
                    <span>
                      {props.author1Name ?? (
                        <Fragment>
                          <span className="testimonial6-text36 thq-body-small">
                            Ananya Shukla
                          </span>
                        </Fragment>
                      )}
                    </span>
                    <span>
                      {props.author1Position ?? (
                        <Fragment>
                          <span className="testimonial6-text38 thq-body-small">
                            Lucknow
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="testimonial6-card1">
                {/* <img
                  alt={props.company2LogoAlt}
                  src={props.company2LogoSrc}
                  className="testimonial6-logo1"
                /> */}
                <h2>Praavi</h2>
                <p>
                  {props.review2 ?? (
                    <Fragment>
                      <p className="testimonial6-text34 thq-body-large">
                        I was worried about hidden fees when booking my tests,
                        but Praavi was very clear and transparent. I could
                        easily compare prices from different labs and choose
                        what fit my budget best. The booking was
                        straightforward, and I received accurate results on
                        time. It’s the kind of service that makes managing your
                        health so much easier and convenient!
                      </p>
                    </Fragment>
                  )}
                </p>
                <div className="testimonial6-avatar1">
                  <img
                    alt={props.author2Alt}
                    src={props.author2Src}
                    className="testimonial6-avatar-image1 thq-img-ratio-1-1 thq-img-round"
                  />
                  <div className="testimonial6-avatar-content1">
                    <span>
                      {props.author2Name ?? (
                        <Fragment>
                          <span className="testimonial6-text41 thq-body-small">
                            Rohit Malhotra
                          </span>
                        </Fragment>
                      )}
                    </span>
                    <span>
                      {props.author2Position ?? (
                        <Fragment>
                          <span className="testimonial6-text39 thq-body-small">
                            Lucknow
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-thq="slider-slide"
              className="testimonial6-slider-slide1 swiper-slide"
            >
              <div className="testimonial6-card2">
                {/* <img
                  alt={props.company3LogoAlt}
                  src={props.company3LogoSrc}
                  className="testimonial6-logo2"
                /> */}
                <h2>Praavi</h2>
                <p>
                  {props.review3 ?? (
                    <Fragment>
                      <span className="testimonial6-text30 thq-body-large">
                        As a working professional, finding time for health
                        checkups is tough. Praavi made it so easy to book a test
                        from a trusted lab near me without leaving my home. The
                        website is user-friendly, and the customer service was
                        excellent. I will definitely use Praavi for all my
                        future health checkups.
                      </span>
                    </Fragment>
                  )}
                </p>
                <div className="testimonial6-avatar2">
                  <img
                    alt={props.author3Alt}
                    src={props.author3Src}
                    className="testimonial6-avatar-image2 thq-img-ratio-1-1 thq-img-round"
                  />
                  <div className="testimonial6-avatar-content2">
                    <span>
                      {props.author3Name ?? (
                        <Fragment>
                          <span className="testimonial6-text35 thq-body-small">
                            Simran Kaur
                          </span>
                        </Fragment>
                      )}
                    </span>
                    <span>
                      {props.author3Position ?? (
                        <Fragment>
                          <span className="testimonial6-text37 thq-body-small">
                            Lucknow
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="testimonial6-card3">
                {/* <img
                  alt={props.company4LogoAlt}
                  src={props.company4LogoSrc}
                  className="testimonial6-logo3"
                /> */}
                <h2>Praavi</h2>
                <p>
                  {props.review4 ?? (
                    <Fragment>
                      <p className="testimonial6-text32 thq-body-large">
                        I used Praavi for a full-body checkup, and I was
                        impressed by how quickly I got my results. The process
                        was smooth, and I felt confident knowing I was choosing
                        from verified local labs. Praavi's team also followed up
                        to ensure everything went well. Fantastic service!
                      </p>
                    </Fragment>
                  )}
                </p>
                <div className="testimonial6-avatar3">
                  <img
                    alt={props.author4Alt}
                    src={props.author4Src}
                    className="testimonial6-avatar-image3 thq-img-ratio-1-1 thq-img-round"
                  />
                  <div className="testimonial6-avatar-content3">
                    <span>
                      {props.author4Name ?? (
                        <Fragment>
                          <span className="testimonial6-text33 thq-body-small">
                            Vivek Ranjan
                          </span>
                        </Fragment>
                      )}
                    </span>
                    <span>
                      {props.author4Position ?? (
                        <Fragment>
                          <span className="testimonial6-text31 thq-body-small">
                            Lucknow
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            data-thq="slider-pagination"
            className="testimonial6-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
          >
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet swiper-pagination-bullet-active"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
          </div>
          <div
            data-thq="slider-button-prev"
            className="swiper-button-prev"
          ></div>
          <div
            data-thq="slider-button-next"
            className="swiper-button-next"
          ></div>
        </div>
        <div
          data-thq="slider"
          data-loop="true"
          data-autoplay="true"
          data-navigation="true"
          data-pagination="true"
          className="testimonial6-slider1 swiper"
        >
          <div data-thq="slider-wrapper" className="swiper-wrapper">
            <div
              data-thq="slider-slide"
              className="testimonial6-slider-slide2 swiper-slide"
            >
              <div className="testimonial6-card4">
                {/* <img
                  alt={props.company1LogoAlt}
                  src={props.company1LogoSrc}
                  className="testimonial6-logo4"
                /> */}
                <h2>Praavi</h2>
                <span style={{ textAlign: "center" }}>
                  Choose from our variety of pricing plans, including Basic,
                  Business, and Enterprise subscriptions, each offering a range
                  of features. Start today!
                </span>
                <div className="testimonial6-avatar4">
                  <img
                    alt={props.author1Alt}
                    src={props.author1Src}
                    className="testimonial6-avatar-image4 thq-img-ratio-1-1 thq-img-round"
                  />
                  <div className="testimonial6-avatar-content4">
                    <span>
                      {/* <span>Author Name</span> */}
                      {props.author1Name ?? (
                        <Fragment>
                          <span className="testimonial6-text36 thq-body-small">
                            Ananya Shukla
                          </span>
                        </Fragment>
                      )}
                    </span>
                    <span>Lucknow</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-thq="slider-slide"
              className="testimonial6-slider-slide3 swiper-slide"
            >
              <div className="testimonial6-card5">
                {/* <img
                  alt={props.company2LogoAlt}
                  src={props.company2LogoSrc}
                  className="testimonial6-logo5"
                /> */}
                <h2>Praavi</h2>
                <span style={{ textAlign: "center" }}>
                  Choose from our variety of pricing plans, including Basic,
                  Business, and Enterprise subscriptions, each offering a range
                  of features. Start today!
                </span>
                <div className="testimonial6-avatar5">
                  <img
                    alt={props.author2Alt}
                    src={props.author2Src}
                    className="testimonial6-avatar-image5 thq-img-ratio-1-1 thq-img-round"
                  />
                  <div className="testimonial6-avatar-content5">
                    <span>
                      {/* <span>Author Name</span> */}
                      {props.author2Name ?? (
                        <Fragment>
                          <span className="testimonial6-text41 thq-body-small">
                            Rohit Malhotra
                          </span>
                        </Fragment>
                      )}
                    </span>
                    <span>Lucknow</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-thq="slider-slide"
              className="testimonial6-slider-slide4 swiper-slide"
            >
              <div className="testimonial6-card6">
                {/* <img
                  alt={props.company3LogoAlt}
                  src={props.company3LogoSrc}
                  className="testimonial6-logo6"
                /> */}
                <h2>Praavi</h2>
                <span style={{ textAlign: "center" }}>
                  Choose from our variety of pricing plans, including Basic,
                  Business, and Enterprise subscriptions, each offering a range
                  of features. Start today!
                </span>
                <div className="testimonial6-avatar6">
                  <img
                    alt={props.author3Alt}
                    src={props.author3Src}
                    className="testimonial6-avatar-image6 thq-img-ratio-1-1 thq-img-round"
                  />
                  <div className="testimonial6-avatar-content6">
                    <span>
                      {/* <span>Author Name</span> */}
                      {props.author3Name ?? (
                        <Fragment>
                          <span className="testimonial6-text35 thq-body-small">
                            Simran Kaur
                          </span>
                        </Fragment>
                      )}
                    </span>
                    <span>
                      <span>Lucknow</span>
                      {/* {props.author3Position ?? (
                        <Fragment>
                          <span className="testimonial6-text37 thq-body-small">
                            Teacher
                          </span>
                        </Fragment>
                      )} */}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-thq="slider-slide"
              className="testimonial6-slider-slide5 swiper-slide"
            >
              <div className="testimonial6-card7">
                {/* <img
                  alt={props.company4LogoAlt}
                  src={props.company4LogoSrc}
                  className="testimonial6-logo7"
                /> */}
                <h2>Praavi</h2>
                <span style={{ textAlign: "center" }}>
                  Choose from our variety of pricing plans, including Basic,
                  Business, and Enterprise subscriptions, each offering a range
                  of features. Start today!
                </span>
                <div className="testimonial6-avatar7">
                  <img
                    alt={props.author4Alt}
                    src={props.author4Src}
                    className="testimonial6-avatar-image7 thq-img-ratio-1-1 thq-img-round"
                  />
                  <div className="testimonial6-avatar-content7">
                    <span>
                      {/* <span>Author Name</span> */}
                      {props.author4Name ?? (
                        <Fragment>
                          <span className="testimonial6-text33 thq-body-small">
                            Vivek Ranjan
                          </span>
                        </Fragment>
                      )}
                    </span>
                    <span>
                      <span>Lucknow</span>
                      {/* {props.author4Position ?? (
                        <Fragment>
                          <span className="testimonial6-text31 thq-body-small">
                            Retired Physician
                          </span>
                        </Fragment>
                      )} */}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            data-thq="slider-pagination"
            className="testimonial6-slider-pagination1 swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
          >
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet swiper-pagination-bullet-active"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
          </div>
          <div
            data-thq="slider-button-prev"
            className="swiper-button-prev"
          ></div>
          <div
            data-thq="slider-button-next"
            className="swiper-button-next"
          ></div>
        </div>
      </div>
    </div>
  );
};

Testimonial6.defaultProps = {
  author3Alt: "Image of Sarah Patel",
  review3: undefined,
  company1LogoSrc:
    "https://presentation-website-assets.teleporthq.io/logos/logo.png",
  author3Src:
    "https://images.unsplash.com/photo-1597983073453-ef06cfc2240e?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  author1Alt: "Image of Ananya Shukla",
  author4Alt: "Image of Vivek Ranjan",
  author4Position: undefined,
  review4: undefined,
  company4LogoSrc:
    "https://presentation-website-assets.teleporthq.io/logos/logo.png",
  author1Src:
    "https://images.unsplash.com/photo-1623091411395-09e79fdbfcf3?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  company2LogoAlt: "Company Logo",
  company4LogoAlt: "Company Logo",
  author4Src:
    "https://images.unsplash.com/photo-1517702145080-e4a4d91435bb?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMjgwMTcwNHw&ixlib=rb-4.0.3&q=80&w=1080",
  company3LogoAlt: "Company Logo",
  company2LogoSrc:
    "https://presentation-website-assets.teleporthq.io/logos/logo.png",
  author4Name: undefined,
  review2: undefined,
  author3Name: undefined,
  author2Alt: "Image of David Lee",
  author1Name: undefined,
  author3Position: undefined,
  author1Position: undefined,
  author2Src:
    "https://images.unsplash.com/photo-1683009427500-71296178737f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMjgwMTcwNHw&ixlib=rb-4.0.3&q=80&w=1080",
  company1LogoAlt: "Company Logo",
  company3LogoSrc:
    "https://presentation-website-assets.teleporthq.io/logos/logo.png",
  author2Position: undefined,
  review1: undefined,
  author2Name: undefined,
};

Testimonial6.propTypes = {
  author3Alt: PropTypes.string,
  review3: PropTypes.element,
  company1LogoSrc: PropTypes.string,
  author3Src: PropTypes.string,
  author1Alt: PropTypes.string,
  author4Alt: PropTypes.string,
  author4Position: PropTypes.element,
  review4: PropTypes.element,
  company4LogoSrc: PropTypes.string,
  author1Src: PropTypes.string,
  company2LogoAlt: PropTypes.string,
  company4LogoAlt: PropTypes.string,
  author4Src: PropTypes.string,
  company3LogoAlt: PropTypes.string,
  company2LogoSrc: PropTypes.string,
  author4Name: PropTypes.element,
  review2: PropTypes.element,
  author3Name: PropTypes.element,
  author2Alt: PropTypes.string,
  author1Name: PropTypes.element,
  author3Position: PropTypes.element,
  author1Position: PropTypes.element,
  author2Src: PropTypes.string,
  company1LogoAlt: PropTypes.string,
  company3LogoSrc: PropTypes.string,
  author2Position: PropTypes.element,
  review1: PropTypes.element,
  author2Name: PropTypes.element,
};

export default Testimonial6;
