import React from "react";

const Details = () => {
    return (
        <section className="home-features1">
            <div className="home-section6">
                <div className="home-content04">
                    <div className="home-header13">
                        <h2 className="home-heading10">
                            Why Trust Your Local Labs?
                        </h2>
                        <p className="home-capton">
                            There's comfort in knowing your health is in the hands of familiar, trusted labs nearby.
                            We connect you to your community's best labs, offering reliable diagnostics with a personal touch.
                        </p>
                    </div>
                    <a href="#diagnostic-centres" className="read-more">
                        <span className="home-text17">Partner diagnostic centers</span>
                        <img
                            alt="image"
                            src="/Icons/arrow-2.svg"
                            className="home-image14"
                        />
                    </a>
                </div>
                <img alt="image" src="/xray-1500w.webp" className="home-image15" />
            </div>
            <div className="home-section7">
                <div className="home-content05">
                    <div className="home-header14">
                        <h2 className="home-heading11">
                            Why Are Accurate Results Important for Your Health?
                        </h2>
                        <p className="home-capton1">
                            Accurate diagnostic reports are the foundation of effective healthcare.
                            That’s why we partner with expert labs, ensuring every test result is reliable, precise, and delivered quickly.                       </p>
                    </div>
                    <a href="#our-practices" className="read-more">
                        <span className="home-text18">Our diagnostic services</span>
                        <img
                            alt="image"
                            src="/Icons/arrow-2.svg"
                            className="home-image16"
                        />
                    </a>
                </div>
                <img alt="image" src="/lab-1500w.webp" className="home-image17" />
            </div>
            <div className="home-section8">
                <div className="home-content06">
                    <div className="home-header15">
                        <h2 className="home-heading12">
                            Why Should You Trust Us with Your Health?                     </h2>
                        <p className="home-capton2">
                            Because your health is too important to leave to chance. We partner with expert labs to deliver precise, trustworthy results that help you make informed decisions for a healthier future.                      </p>
                    </div>
                    <a href="#home-section" className="button button-main home-book3">
                        <span>Book a test</span>
                    </a>
                </div>
                <img
                    alt="image"
                    src="/examination-1500w.webp"
                    className="home-image18"
                />
            </div>
            <a href="#home-section" className="home-book4 button button-main">
                <span>Book a test</span>
            </a>
        </section>
    )
}

export default Details