import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './pricing6.css'

const Pricing6 = (props) => {
  const [isMonthly, setIsMonthly] = useState(true)
  return (
    <div
      className={`pricing6-pricing23 thq-section-padding ${props.rootClassName} `}
    >
      <div className="pricing6-max-width thq-section-max-width">
        <div className="pricing6-section-title">
          <span>
            {props.content1 ?? (
              <Fragment>
                <span className="pricing6-text059 thq-body-small">
                  Choose the perfect plan for you
                </span>
              </Fragment>
            )}
          </span>
          <div className="pricing6-content">
            <h2>
              {props.heading1 ?? (
                <Fragment>
                  <h2 className="pricing6-text089 thq-heading-2">
                    Pricing plan
                  </h2>
                </Fragment>
              )}
            </h2>
            <p>
              {props.content2 ?? (
                <Fragment>
                  <p className="pricing6-text095 thq-body-large">
                    <span>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </p>
                </Fragment>
              )}
            </p>
          </div>
        </div>
        <div className="pricing6-tabs">
          {isMonthly && (
            <button
              onClick={() => setIsMonthly(true)}
              className="pricing6-button thq-button-filled"
            >
              <span className="thq-body-small">Monthly</span>
            </button>
          )}
          {!isMonthly && (
            <button
              onClick={() => setIsMonthly(true)}
              className="pricing6-button01 thq-button-outline"
            >
              <span className="thq-body-small">Monthly</span>
            </button>
          )}
          {isMonthly && (
            <button
              onClick={() => setIsMonthly(false)}
              className="pricing6-button02 thq-button-outline"
            >
              <span className="thq-body-small">Yearly</span>
            </button>
          )}
          {!isMonthly && (
            <button
              onClick={() => setIsMonthly(false)}
              className="pricing6-button03 thq-button-filled"
            >
              <span className="thq-body-small">Yearly</span>
            </button>
          )}
        </div>
        {!isMonthly && (
          <div className="pricing6-container">
            <div className="pricing6-column thq-card">
              <div className="pricing6-price">
                <div className="pricing6-price01">
                  <span>
                    {props.plan1 ?? (
                      <Fragment>
                        <span className="pricing6-text080 thq-body-large">
                          Basic Plan
                        </span>
                      </Fragment>
                    )}
                  </span>
                  <h3>
                    {props.plan1Price ?? (
                      <Fragment>
                        <h3 className="pricing6-text069 thq-heading-3">
                          $19.99/month
                        </h3>
                      </Fragment>
                    )}
                  </h3>
                  <span>
                    {props.plan1Yearly ?? (
                      <Fragment>
                        <span className="pricing6-text091 thq-body-large">
                          $199.99/year
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <div className="pricing6-list">
                  <div className="pricing6-list-item">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan1Feature1 ?? (
                        <Fragment>
                          <span className="pricing6-text099 thq-body-small">
                            Access to Symptom Checker Tool
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing6-list-item01">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan1Feature2 ?? (
                        <Fragment>
                          <span className="pricing6-text055 thq-body-small">
                            General Medical Information
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing6-list-item02">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan1Feature3 ?? (
                        <Fragment>
                          <span className="pricing6-text072 thq-body-small">
                            Discounts on Specialty Tests
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <button className="pricing6-button04 thq-button-outline">
                <span>
                  {props.plan1Action ?? (
                    <Fragment>
                      <span className="pricing6-text070 thq-body-small">
                        Sign Up Now
                      </span>
                    </Fragment>
                  )}
                </span>
              </button>
            </div>
            <div className="pricing6-column1 thq-card">
              <div className="pricing6-price02">
                <div className="pricing6-price03">
                  <span>
                    {props.plan2 ?? (
                      <Fragment>
                        <span className="pricing6-text075 thq-body-large">
                          Standard Plan
                        </span>
                      </Fragment>
                    )}
                  </span>
                  <h3>
                    {props.plan2Price ?? (
                      <Fragment>
                        <h3 className="pricing6-text079 thq-heading-3">
                          $29.99/month
                        </h3>
                      </Fragment>
                    )}
                  </h3>
                  <span>
                    {props.plan2Yearly ?? (
                      <Fragment>
                        <span className="pricing6-text098 thq-body-large">
                          $299.99/year
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <div className="pricing6-list1">
                  <div className="pricing6-list-item03">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan2Feature1 ?? (
                        <Fragment>
                          <span className="pricing6-text063 thq-body-small">
                            All Basic Plan features
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing6-list-item04">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan2Feature2 ?? (
                        <Fragment>
                          <span className="pricing6-text100 thq-body-small">
                            Virtual Appointments Booking
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing6-list-item05">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan2Feature3 ?? (
                        <Fragment>
                          <span className="pricing6-text058 thq-body-small">
                            Access to Healthcare Resources
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing6-list-item06">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan2Feature4 ?? (
                        <Fragment>
                          <span className="pricing6-text085 thq-body-small">
                            Discounts on Specialty Tests
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <button className="pricing6-button05 thq-button-filled">
                <span>
                  {props.plan2Action ?? (
                    <Fragment>
                      <span className="pricing6-text077 thq-body-small">
                        Sign Up Now
                      </span>
                    </Fragment>
                  )}
                </span>
              </button>
            </div>
            <div className="pricing6-column2 thq-card">
              <div className="pricing6-price04">
                <div className="pricing6-price05">
                  <span>
                    {props.plan3 ?? (
                      <Fragment>
                        <span className="pricing6-text067 thq-body-large">
                          Premium Plan
                        </span>
                      </Fragment>
                    )}
                  </span>
                  <h3>
                    {props.plan3Price ?? (
                      <Fragment>
                        <h3 className="pricing6-text066 thq-heading-3">
                          $49.99/month
                        </h3>
                      </Fragment>
                    )}
                  </h3>
                  <span>
                    {props.plan3Yearly ?? (
                      <Fragment>
                        <span className="pricing6-text088 thq-body-large">
                          $499.99/year
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <div className="pricing6-list2">
                  <div className="pricing6-list-item07">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan3Feature1 ?? (
                        <Fragment>
                          <span className="pricing6-text102 thq-body-small">
                            All Standard Plan features
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing6-list-item08">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan3Feature2 ?? (
                        <Fragment>
                          <span className="pricing6-text057 thq-body-small">
                            Meet Experienced General Practitioners and
                            Specialists
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing6-list-item09">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan3Feature3 ?? (
                        <Fragment>
                          <span className="pricing6-text065 thq-body-small">
                            Priority Booking for Specialist Appointments
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing6-list-item10">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan3Feature4 ?? (
                        <Fragment>
                          <span className="pricing6-text062 thq-body-small">
                            Nutrition Consultation Included
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <button className="pricing6-button06 thq-button-filled">
                <span>
                  {props.plan3Action ?? (
                    <Fragment>
                      <span className="pricing6-text064 thq-body-small">
                        Sign Up Now
                      </span>
                    </Fragment>
                  )}
                </span>
              </button>
            </div>
          </div>
        )}
        {isMonthly && (
          <div className="pricing6-container1">
            <div className="pricing6-column3 thq-card">
              <div className="pricing6-price06">
                <div className="pricing6-price07">
                  <p>
                    {props.plan11 ?? (
                      <Fragment>
                        <p className="pricing6-text068 thq-body-large">
                          Basic plan
                        </p>
                      </Fragment>
                    )}
                  </p>
                  <h3>
                    {props.plan1Price1 ?? (
                      <Fragment>
                        <h3 className="pricing6-text076 thq-heading-3">
                          $200/year
                        </h3>
                      </Fragment>
                    )}
                  </h3>
                  <p>
                    {props.plan1Yearly1 ?? (
                      <Fragment>
                        <p className="pricing6-text074 thq-body-large">
                          or $20/month
                        </p>
                      </Fragment>
                    )}
                  </p>
                </div>
                <div className="pricing6-list3">
                  <div className="pricing6-list-item11">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan1Feature11 ?? (
                        <Fragment>
                          <span className="pricing6-text060 thq-body-small">
                            Priority Booking for Appointments
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing6-list-item12">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan1Feature21 ?? (
                        <Fragment>
                          <span className="pricing6-text071 thq-body-small">
                            Feature text goes here
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing6-list-item13">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan1Feature31 ?? (
                        <Fragment>
                          <span className="pricing6-text094 thq-body-small">
                            Feature text goes here
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <button className="pricing6-button07 thq-button-outline">
                <span>
                  {props.plan1Action1 ?? (
                    <Fragment>
                      <span className="pricing6-text087 thq-body-small">
                        Get started
                      </span>
                    </Fragment>
                  )}
                </span>
              </button>
            </div>
            <div className="pricing6-column4 thq-card">
              <div className="pricing6-price08">
                <div className="pricing6-price09">
                  <p>
                    {props.plan21 ?? (
                      <Fragment>
                        <p className="pricing6-text054 thq-body-large">
                          Business plan
                        </p>
                      </Fragment>
                    )}
                  </p>
                  <h3>
                    {props.plan2Price1 ?? (
                      <Fragment>
                        <h3 className="pricing6-text101 thq-heading-3">
                          $299/year
                        </h3>
                      </Fragment>
                    )}
                  </h3>
                  <p>
                    {props.plan2Yearly1 ?? (
                      <Fragment>
                        <p className="pricing6-text081 thq-body-large">
                          or $29/month
                        </p>
                      </Fragment>
                    )}
                  </p>
                </div>
                <div className="pricing6-list4">
                  <div className="pricing6-list-item14">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan2Feature11 ?? (
                        <Fragment>
                          <span className="pricing6-text086 thq-body-small">
                            Feature text goes here
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing6-list-item15">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan2Feature21 ?? (
                        <Fragment>
                          <span className="pricing6-text083 thq-body-small">
                            Feature text goes here
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing6-list-item16">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan2Feature31 ?? (
                        <Fragment>
                          <span className="pricing6-text084 thq-body-small">
                            Feature text goes here
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing6-list-item17">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan2Feature41 ?? (
                        <Fragment>
                          <span className="pricing6-text078 thq-body-small">
                            Feature text goes here
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <button className="pricing6-button08 thq-button-filled">
                <span>
                  {props.plan2Action1 ?? (
                    <Fragment>
                      <span className="pricing6-text073 thq-body-small">
                        Get started
                      </span>
                    </Fragment>
                  )}
                </span>
              </button>
            </div>
            <div className="pricing6-column5 thq-card">
              <div className="pricing6-price10">
                <div className="pricing6-price11">
                  <p>
                    {props.plan31 ?? (
                      <Fragment>
                        <p className="pricing6-text090 thq-body-large">
                          Enterprise plan
                        </p>
                      </Fragment>
                    )}
                  </p>
                  <h3>
                    {props.plan3Price1 ?? (
                      <Fragment>
                        <h3 className="pricing6-text082 thq-heading-3">
                          Contact Us
                        </h3>
                      </Fragment>
                    )}
                  </h3>
                  <p>
                    {props.plan3Yearly1 ?? (
                      <Fragment>
                        <p className="pricing6-text053 thq-body-large">
                          (080) - 800-800
                        </p>
                      </Fragment>
                    )}
                  </p>
                </div>
                <div className="pricing6-list5">
                  <div className="pricing6-list-item18">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan3Feature11 ?? (
                        <Fragment>
                          <span className="pricing6-text093 thq-body-small">
                            Feature text goes here
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing6-list-item19">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan3Feature21 ?? (
                        <Fragment>
                          <span className="pricing6-text103 thq-body-small">
                            Extra feature
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing6-list-item20">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan3Feature31 ?? (
                        <Fragment>
                          <span className="pricing6-text092 thq-body-small">
                            Custom feature
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pricing6-list-item21">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span>
                      {props.plan3Feature41 ?? (
                        <Fragment>
                          <span className="pricing6-text061 thq-body-small">
                            Premium feature
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <button className="pricing6-button09 thq-button-filled">
                <span>
                  {props.plan3Action1 ?? (
                    <Fragment>
                      <span className="pricing6-text056 thq-body-small">
                        Email Us
                      </span>
                    </Fragment>
                  )}
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

Pricing6.defaultProps = {
  plan3Yearly1: undefined,
  plan21: undefined,
  plan1Feature2: undefined,
  plan3Action1: undefined,
  plan3Feature2: undefined,
  plan2Feature3: undefined,
  content1: undefined,
  plan1Feature11: undefined,
  plan3Feature41: undefined,
  plan3Feature4: undefined,
  plan2Feature1: undefined,
  plan3Action: undefined,
  plan3Feature3: undefined,
  plan3Price: undefined,
  plan3: undefined,
  plan11: undefined,
  plan1Price: undefined,
  plan1Action: undefined,
  plan1Feature21: undefined,
  plan1Feature3: undefined,
  plan2Action1: undefined,
  plan1Yearly1: undefined,
  plan2: undefined,
  plan1Price1: undefined,
  plan2Action: undefined,
  plan2Feature41: undefined,
  plan2Price: undefined,
  plan1: undefined,
  plan2Yearly1: undefined,
  plan3Price1: undefined,
  plan2Feature21: undefined,
  plan2Feature31: undefined,
  plan2Feature4: undefined,
  plan2Feature11: undefined,
  plan1Action1: undefined,
  plan3Yearly: undefined,
  heading1: undefined,
  plan31: undefined,
  plan1Yearly: undefined,
  plan3Feature31: undefined,
  plan3Feature11: undefined,
  plan1Feature31: undefined,
  content2: undefined,
  plan2Yearly: undefined,
  plan1Feature1: undefined,
  plan2Feature2: undefined,
  plan2Price1: undefined,
  plan3Feature1: undefined,
  rootClassName: '',
  plan3Feature21: undefined,
}

Pricing6.propTypes = {
  plan3Yearly1: PropTypes.element,
  plan21: PropTypes.element,
  plan1Feature2: PropTypes.element,
  plan3Action1: PropTypes.element,
  plan3Feature2: PropTypes.element,
  plan2Feature3: PropTypes.element,
  content1: PropTypes.element,
  plan1Feature11: PropTypes.element,
  plan3Feature41: PropTypes.element,
  plan3Feature4: PropTypes.element,
  plan2Feature1: PropTypes.element,
  plan3Action: PropTypes.element,
  plan3Feature3: PropTypes.element,
  plan3Price: PropTypes.element,
  plan3: PropTypes.element,
  plan11: PropTypes.element,
  plan1Price: PropTypes.element,
  plan1Action: PropTypes.element,
  plan1Feature21: PropTypes.element,
  plan1Feature3: PropTypes.element,
  plan2Action1: PropTypes.element,
  plan1Yearly1: PropTypes.element,
  plan2: PropTypes.element,
  plan1Price1: PropTypes.element,
  plan2Action: PropTypes.element,
  plan2Feature41: PropTypes.element,
  plan2Price: PropTypes.element,
  plan1: PropTypes.element,
  plan2Yearly1: PropTypes.element,
  plan3Price1: PropTypes.element,
  plan2Feature21: PropTypes.element,
  plan2Feature31: PropTypes.element,
  plan2Feature4: PropTypes.element,
  plan2Feature11: PropTypes.element,
  plan1Action1: PropTypes.element,
  plan3Yearly: PropTypes.element,
  heading1: PropTypes.element,
  plan31: PropTypes.element,
  plan1Yearly: PropTypes.element,
  plan3Feature31: PropTypes.element,
  plan3Feature11: PropTypes.element,
  plan1Feature31: PropTypes.element,
  content2: PropTypes.element,
  plan2Yearly: PropTypes.element,
  plan1Feature1: PropTypes.element,
  plan2Feature2: PropTypes.element,
  plan2Price1: PropTypes.element,
  plan3Feature1: PropTypes.element,
  rootClassName: PropTypes.string,
  plan3Feature21: PropTypes.element,
}

export default Pricing6
