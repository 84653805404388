import React, { useEffect, useState, useCallback } from "react";
import Features from "../features";
import { useNavigate } from "react-router-dom";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import Navbar from "../Navbar/Navbar";
import { locationService } from "../../services/locationService";
import { testService } from "../../services/testService";
import { diagnosticCenterService } from "../../services/diagnosticCenterService";

const HomeHero = () => {
  const [allLocations, setAllLocations] = useState([]);
  const [allTests, setAllTests] = useState([]);
  const [errors, setErrors] = useState({ testError: "", locationError: "" });
  const [selectedTest, setSelectedTest] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [diagnosticCenters, setDiagnosticCenters] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [locationResponse, testResponse] = await Promise.all([
          locationService.allLocation(),
          testService.allTest(),
        ]);
        setAllLocations(locationResponse.data);
        setAllTests(testResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedTest && selectedLocation) {
      const fetchDiagnosticCenters = async () => {
        try {
          const response = await diagnosticCenterService.findDiagnosticCenter(
            selectedLocation,
            selectedTest
          );
          setDiagnosticCenters(response.data);
        } catch (error) {
          console.error("Error fetching diagnostic centers:", error);
        }
      };

      fetchDiagnosticCenters();
    } else {
      setDiagnosticCenters([]); 
    }
  }, [selectedTest, selectedLocation]);

  const handleBookTest = useCallback(() => {
    const test = allTests.find((test) => test._id === selectedTest);
    const errors = {
      testError: selectedTest ? "" : "* Please select a test",
      locationError: selectedLocation ? "" : "* Please select a location",
    };

    setErrors(errors);

    if (!errors.testError && !errors.locationError) {
      const selectedTestName = test ? test.testName : "";
      navigate("pricing", {
        state: {
          selectedTest,
          selectedLocation,
          selectedTestName,
          diagnosticCenters,
        },
      });
    }
  }, [selectedTest, selectedLocation, allTests, navigate, diagnosticCenters]);

  return (
    <section id="home-section" className="home-hero">
      <Navbar homePage={true} />
      <div className="home-main">
        <div className="home-content">
          <div className="home-heading07">
            <h1 className="home-header08">
              Seamless Diagnostic Testing from Trusted Labs Near You
            </h1>
            <p className="home-caption6">
              Find and book diagnostic tests online with ease. Praavi connects
              you to nearby trusted labs in just a few clicks.
            </p>
          </div>
          <div className="dropdown-book-button">
            <CustomDropdown
              items={allTests}
              placeholder="Select your test"
              handleChange={setSelectedTest}
              errorText={errors.testError}
            />
            <CustomDropdown
              items={allLocations}
              placeholder="Select your location"
              handleChange={setSelectedLocation}
              errorText={errors.locationError}
            />

            <button
              className="home-book2 button button-main"
              onClick={handleBookTest}
            >
              <img
                alt="calendar icon"
                src="/Icons/calendar.svg"
                className="home-image10"
              />
              <span className="button-text">Book a test</span>
            </button>
          </div>
        </div>
        <div className="home-image11">
          <img
            alt="doctor"
            src="/doctor-image-1500w.png"
            className="home-image12"
          />
        </div>
      </div>
      <div id="booking-process" className="home-features">
        <div className="home-content01">
          <Features
            title="Search & Book a Test"
            description="Select your test or package and book online effortlessly."
            icon="/Icons/magnifying-glass.svg"
          />
          <Features
            title="Home Sample Collection"
            description="Choose a time and place. We'll collect your sample when it suits you."
            icon="/Icons/microscope.svg"
          />
          <Features
            title="Get Your Results"
            description="Receive your results directly via email and WhatsApp."
            icon="/Icons/result.svg"
          />
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
