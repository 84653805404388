import React, { Fragment } from "react";

const Card = ({ props, handleClick, details }) => {
  const { finalPrice, originalPrice } = details;
  const percentage = Math.round(
    ((originalPrice - finalPrice) / originalPrice) * 100
  );
  return (
    <div className="pricing3-content1 thq-card">
      <div className="pricing3-price-title">
        <div className="pricing3-price-title1">
          <img
            className="card-image"
            src="/Icons/microscope.svg"
            alt=""
            style={{
              filter: "brightness(0) invert(0)",
            }}
          />

          <div className="pricing3-title">
            <p>
              {props.plan1 ?? (
                <Fragment>
                  <p className="pricing3-text22 thq-body-large">
                    {details.centerName}
                  </p>
                </Fragment>
              )}
            </p>
            <p>
              {props.plan1Detail ?? (
                <Fragment>
                  <p className="pricing3-text18 thq-body-large">
                    {details.centerAddress}
                  </p>
                </Fragment>
              )}
            </p>
          </div>
        </div>
        <h3>
          {props.plan1Price ?? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h3 className="pricing3-text29 thq-heading-3">
                ₹ {details.finalPrice}
              </h3>
              <div className="pricing3-text99">
                <h5 className="pricing3-text36 thq-heading-3">
                  <s>₹ {details.originalPrice}</s>
                </h5>
                <p style={{ fontWeight: 350 }}>{percentage}% off</p>
              </div>
            </div>
          )}
        </h3>
      </div>
      <div className="thq-divider-horizontal"></div>
      <div className="pricing3-content2">
        {/* <span>
          {props.plan1Includes ?? (
            <Fragment>
              <span className="pricing3-text23 thq-body-small">
                Virtual appointments, Symptom checker tool
              </span>
            </Fragment>
          )}
        </span> */}
        <div className="pricing3-content3">
          <div className="pricing3-list">
            <div className="pricing3-list-item">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
              <span>
                {props.plan1Feature1 ?? (
                  <Fragment>
                    <span className="pricing3-text30 thq-body-small">
                      {details.experience} of diagnostic expertise
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
            <div className="pricing3-list-item01">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
              <span>
                {props.plan1Feature2 ?? (
                  <Fragment>
                    <span className="pricing3-text31 thq-body-small">
                      Get results within {details.deliveryTime}
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
          <div className="pricing3-list1">
            <div className="pricing3-list-item05">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
              <span>
                {props.plan1Feature6 ?? (
                  <Fragment>
                    <span className="pricing3-text26 thq-body-small">
                      {details.parameters} diagnostic testing parameters
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
            <div className="pricing3-list-item06">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
              <span>
                {props.plan1Feature7 ?? (
                  <Fragment>
                    <span className="pricing3-text32 thq-body-small">
                      {details.notes}
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      <button
        className="pricing3-button thq-button-filled"
        onClick={handleClick}
      >
        <span>
          {props.plan1Action ?? (
            <Fragment>
              <span className="pricing3-text27 thq-body-small">Book Now</span>
            </Fragment>
          )}
        </span>
      </button>
    </div>
  );
};

export default Card;
